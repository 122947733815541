import { activeConfig } from 'config/clientConfig';
import React from 'react';

interface FeatureFlagProps {
    name: string;
    children: React.ReactNode;
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({ name, children }) => {
    if (activeConfig.features[name]) {
        return <>{children}</>;
    }
    return null;
};

export default FeatureFlag;