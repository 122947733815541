import { activeConfig } from 'config/clientConfig';
import { APICore } from './apiCore';

const api = new APICore();

function login(params: { email: string; password: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/login/`;
    const payload = {
        email: params.email,
        password: params.password,
    }

    return api.create(`${baseUrl}`, payload);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: {
    user_name: string, name: string, email: string, password: string, reference_id: string, last_name: string, birth_date: string, phone: string,
    gender_id: number, address: string, birth_address: string
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/signup/`;
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/forget-password/`;
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

function resetPassword(params: { resetKey: string, password: string, confirmPassword: string }) {
    const baseUrl = '/reset-password/finish/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm, resetPassword };
