import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEs from 'locales/es/translation.json';
import translationEn from 'locales/en/translation.json';
import translationPt from 'locales/br/translation.json';
import { activeConfig } from 'config/clientConfig';

const defaultLang = activeConfig.theme.defaultLang;
const isMultiLang = activeConfig.languages.multi;
// translations
const resources = {
    es: {
        translation: translationEs,
    },
    en: {
        translation: translationEn,
    },
    br: {
        translation: translationPt,
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: (localStorage.getItem("language") && isMultiLang ? localStorage.getItem("language") : defaultLang) || defaultLang, // Usa el idioma guardado o el inglés por defecto
        fallbackLng: defaultLang, // use en if detected lng is not available

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
