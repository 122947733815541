import { APICore } from './apiCore';
import { activeConfig } from 'config/clientConfig';

const api = new APICore();

function getTemps(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Temperature',
    }

    return api.create(`${baseUrl}`, payload);
}

function getWeights(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Weight',
    }

    return api.create(`${baseUrl}`, payload);
}

function getOxygenSaturations(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Oxygen saturation',
    }

    return api.create(`${baseUrl}`, payload);
}

function getGlucose(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Glucose',
    }

    return api.create(`${baseUrl}`, payload);
}

function getCholesterol(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Cholesterol',
    }

    return api.create(`${baseUrl}`, payload);
}

function getUricAcid(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Uric Acid',
    }

    return api.create(`${baseUrl}`, payload);
}

function getLactate(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Lactate',
    }

    return api.create(`${baseUrl}`, payload);
}

function getTriglycerides(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Triglycerides',
    }

    return api.create(`${baseUrl}`, payload);
}

function getKetone(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Ketone',
    }

    return api.create(`${baseUrl}`, payload);
}

function getBloodPressure(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
        type_measurement: 'Blood Pressure',
    }

    return api.create(`${baseUrl}`, payload);
}

function getLastMeasurements(params: { uuid_user: string, month: string, year: string }) {
    const baseUrl = '/getLastMeasurements/';
    const payload = {
        uuid_user: params.uuid_user,
        month: params.month,
        year: params.year,
    }

    return api.create(`${baseUrl}`, payload);
}

function getDevice(params: { active_flag: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getDevice/`;
    const payload = {
        active_flag: params.active_flag,
    }

    return api.create(`${baseUrl}`, payload);
}

function updateDeviceBlockActivate(params: { uuid: string, uuid_edit: string }) {
    const baseUrl = '/updateDeviceBlockActivate/';
    const payload = {
        uuid_device: params.uuid,
        uuid_user: params.uuid_edit
    }

    return api.create(`${baseUrl}`, payload);
}



function addMeasurement(params: { uuid_user: string, value: string, uuid_device: string, measurement_date: string, type_measurement: string }) {
    const baseUrl = '/addMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        value: params.value,
        uuid_device: params.uuid_device,
        measurement_date: params.measurement_date,
        type_measurement: params.type_measurement
    }

    return api.create(`${baseUrl}`, payload);
}
function UpdateMeasurement(params: { uuid_user: string, measurementId: string, statusCurrent: string, comment: string }) {
    const baseUrl = '/UpdateMeasurement/';
    const payload = {
        uuid_user: params.uuid_user,
        measurement_id: parseFloat(params.measurementId),
        status_measurement: params.statusCurrent,
        comment: params.comment,

    }

    return api.create(`${baseUrl}`, payload);
}
function UpdateNotification(params: { uuid_user: string, measurementId: string, statusCurrent: string, comment: string }) {
    const baseUrl = '/UpdateNotification/';
    const payload = {
        uuid_user: params.uuid_user,
        measurement_id: parseFloat(params.measurementId),
        status_measurement: params.statusCurrent,
        comment: params.comment,

    }

    return api.create(`${baseUrl}`, payload);
}

export {
    addMeasurement, getTemps, getWeights, getOxygenSaturations, getGlucose, getBloodPressure, getCholesterol, getUricAcid, getLactate, getTriglycerides, getKetone, getLastMeasurements
    , getDevice, updateDeviceBlockActivate, UpdateMeasurement, UpdateNotification
};
